/* LUFGA */

@font-face {
  font-family: "Lufga-Bold";
  src: url("../../public/fonts/Lufga-Bold.woff") format("woff");
}

@font-face {
  font-family: "Lufga-SemiBold";
  src: url("../../public/fonts/Lufga-SemiBold.otf");
}

@font-face {
  font-family: "Lufga-Medium";
  src: url("../../public/fonts/Lufga-Medium.woff") format("woff");
}

@font-face {
  font-family: "Lufga-Regular";
  src: url("../../public/fonts/Lufga-Regular.woff") format("woff");
}

@font-face {
  font-family: "Lufga-ExtraLight";
  src: url("../../public/fonts/Lufga-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Lufga-Thin";
  src: url("../../public/fonts/Lufga-Thin.woff") format("woff");
}

/* VISBY */

@font-face {
  font-family: "Visby-Medium";
  src: url("../../public/fonts/VisbyCF-Medium.woff") format("woff");
}

@font-face {
  font-family: "Visby-DemiBold";
  src: url("../../public/fonts/VisbyCF-DemiBold.woff") format("woff");
}

@font-face {
  font-family: "Visby-Bold";
  src: url("../../public/fonts/VisbyCF-Bold.woff") format("woff");
}

@font-face {
  font-family: "Visby-ExtraBold";
  src: url("../../public/fonts/VisbyCF-ExtraBold.woff") format("woff");
}

/* OUTFIT */

@font-face {
  font-family: "Outfit-Bold";
  src: url("../../public/fonts/Outfit-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-SemiBold";
  src: url("../../public/fonts/Outfit-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-Regular";
  src: url("../../public/fonts/Outfit-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-Medium";
  src: url("../../public/fonts/Outfit-Medium.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Lufga-Regular";
  font-size: 16px;
  font-weight: 400 !important;
}

body {
  overflow-x: hidden;
  background: #000;
}

a {
  color: inherit;
  text-decoration: none;
  transform: all 0.3s;
}

a:hover {
  color: #feb806;
}

img {
  max-width: 100%;
}

h2 {
  color: #fff;
  font-family: "Lufga-ExtraLight";
  font-weight: 300;
  font-size: 4.813rem;
  line-height: 1.3;
}

@media only screen and (min-width: 1280px) and (max-width: 1920px) {
  h2 {
    font-size: calc(3rem + (4.813 - 3) * ((100vw - 1280px) / (1920 - 1280)));
  }
}

@media only screen and (min-width: 1920px) {
  h2 {
    font-size: 4.813rem;
  }
}

@media only screen and (max-width: 700px) {
  h2 {
    font-size: 36px;
  }
}